import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Link,useParams} from "react-router-dom";
import { useEffect } from "react";
import { Logout } from "../../../utils/helpers/Logout";
import {  Container} from "reactstrap";
import { editContentQuiz,getContentQuizDetails } from "../../../store/slices/faq";
import TextEditors from '../../Fundamentals/fundamentalMaterial/Ediitors';

function EditContent(){

    const { id,id1 } = useParams();
  
    const navigate = useNavigate();
  const [dateError, setDateError] = useState("");
  const [textError, setTextError] = useState("");
  const dispatch = useDispatch();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState({});


 

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout()
  }, []);



  const handleChange = (e) => {
   
    let { name, value } = e.target;
    switch (name) {
      case "date":
        setDateError(value === "" ? "Please enter date" : "");
        break;
      case "content":
        setTextError(value === "" ? "Please enter content" : "");
        break;
      default:
        break;
    }
    setData({
      ...data,
      [name]: value,
    });
  }

  React.useEffect(() => {
    const item = {
     quizCategoryId: id,
      id: id1,
    };
dispatch(getContentQuizDetails(item))
.unwrap()
.then((data)=>{
    const initialData = data.content.data[0];
    setData({
        date: (initialData.date).toString().split("T")[0],
        content: initialData.content,
    }) 
    setEditorLoaded(true);
})
.catch(({ message }) => {
    alert(message);
  });
    },[dispatch]);

    
  const save = (e) => {
    
      e.preventDefault();
      let isValid = true
      if (data.content === "" || data.content ==="<p><br></p>") {
        setTextError("Please enter content");
        isValid= false
      }  if (data.date === "") {
        setDateError("please enter date");
        isValid= false
      }
  
      if(!isValid){
        return
      }else {

  const editPayload = {
    quizCategoryId:id,
    date:data.date,
    content:data.content,
    id:id1,
  };
   
  dispatch(editContentQuiz(editPayload))
  .unwrap()
  .then((data) => {
   
    alert("Content updated succesfully..");
    navigate(-1);
  })
  .catch(({ message }) => {
    alert(message);
  });
  }
}

  const handleCancelClick = () => {
    navigate(`/quizcontent/${id}`);
  };
        return (
            <>
            <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
                  <div
                    class="text-start my-2 ms-1"
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
                    <Link to="/quiz">Quiz</Link>
                    &nbsp;&#8811;<Link to ={`/quizcontent/${id}`}> Quiz Content</Link>
                    &nbsp;&#8811;&nbsp; Edit Content
                  </div>
                  <div class="card">
                    <div class="card-header">
                      <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                        <h4 class="card-title">Edit Content </h4>
                        &nbsp;
                        
                      </div>
                    </div>
                    <section className="form-Questionnaire p-4">
                    <div
                        style={{ height: "100%" }}
                        className="col-md-6 col-sm-12"
                      >
                        <span className="bread-head1 mt-3">Date</span>

                        <div className="pt-2 pb-2">
                          <input
                            type="date"
                            required="true"
                            name="date"
                            className="form-control "
                            placeholder="Enter date"
                            onChange={(e) => handleChange(e)}
                            value={data.date}
                          />
                        </div>
                        <p className="alert-message">{dateError}</p>
                      </div>
                      <div className="col-md-11 col-sm-12 mt-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <label className="bread-head1">
                             Content :
                            </label>
                          </div>

                          <TextEditors value={data.content} handleChange={handleChange} />

                          <p className="alert-message">{textError}</p>
                        </div>
                            <div className="col-md-12 mt-3" align="center">
                          <button
                            className="btn btn-outline-info wd-100"
                            onClick={handleCancelClick}
                            
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn btn-info wd-100 ms-2"
                            onClick={save}
                            
                          >
                            Submit
                          </button>
                        </div>
                        </section>
                </div>
                </Container>
              </div>
          </React.Fragment>


              </>
        )
    }

export default EditContent;