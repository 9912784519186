import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  editfundamentalMaterial,
  getsinglefundamentalMaterials,
} from "../../../store/slices/fundamentals";
import TextEditor from "./Editor";
import { Logout } from "../../../utils/helpers/Logout";

const EditFundamentalMaterial = () => {
  const { id1, id2 } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    materialName: "",
    materialNumber: "",
    url: "",
    description: "", // ✅ Ensure it's initialized
  });
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    Logout();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const item = { id1, id2 };
    dispatch(getsinglefundamentalMaterials(item))
      .unwrap()
      .then((response) => {
        console.log("🔹 API Response:", response);

        if (response?.fundamentalmatrial?.data?.length > 0) {
          const initialData = response.fundamentalmatrial.data[0];

          setData({
            materialName: initialData?.materialName || "",
            materialNumber: initialData?.materialNumber || "",
            url: initialData?.url || "",
            description: initialData?.description || "", // ✅ Ensuring correct description
          });

          console.log("📝 Setting Description:", initialData?.description);
        } else {
          console.error("❌ No data found in API response");
        }
      })
      .catch(({ message }) => {
        console.error("❌ API Error:", message);
        alert(message);
      })
      .finally(() => {
        setLoading(false); // ✅ Prevents UI from breaking before data loads
      });
  }, [dispatch, id1, id2]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    let newErrors = {};

    if (!data.materialName.trim()) {
      newErrors.materialName = "Please enter material name";
    }
    if (!data.materialNumber) {
      newErrors.materialNumber = "Please enter material number";
    } else if (data.materialNumber <= 0) {
      newErrors.materialNumber = "Please enter a valid number";
    }
    if (!data.description.trim()) {
      newErrors.description = "Please enter a valid description";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveMaterial = async () => {
    if (!validateForm()) {
      return;
    }

    setButtonDisabled(true);
    const item = {
      id: id2,
      materialName: data.materialName,
      materialNumber: data.materialNumber,
      description: data.description,
      url: data.url,
      unitId: id1,
      status: 1,
      type: "pdf",
    };

    try {
      const res = await dispatch(editfundamentalMaterial(item)).unwrap();
      if (res.fundamentalmatrial.data) {
        alert(res.fundamentalmatrial.message);
        navigate(`/fundamentals/materials/${id1}`);
      }
    } catch (error) {
      console.error("❌ Save Error:", error);
      alert(error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // ✅ Prevents UI from breaking
  }

  return (
    <div>
      <div className="page-content">
        <Container fluid={true}>
          <div className="card">
            <div className="card-body collapse show">
              <div className="card-block card-dashboard table-responsive">
                <div className="row">
                  <div className="col-md-6 col-sm-12 mt-2">
                    <label className="bread-head1">Topic Name:</label>
                    <input
                      type="text"
                      name="materialName"
                      className="form-control"
                      placeholder="Topic Name"
                      value={data.materialName}
                      onChange={handleChange}
                    />
                    {errors.materialName && (
                      <p className="text-danger">{errors.materialName}</p>
                    )}
                  </div>

                  <div className="col-md-6 col-sm-12 mt-2">
                    <label className="bread-head1">Topic Number:</label>
                    <input
                      type="number"
                      name="materialNumber"
                      className="form-control"
                      placeholder="Topic Number"
                      onChange={handleChange}
                      value={data.materialNumber}
                    />
                    {errors.materialNumber && (
                      <p className="text-danger">{errors.materialNumber}</p>
                    )}
                  </div>
                </div>

                <div className="col-md-12 col-sm-12 mt-2">
                  <TextEditor
                    value={data.description}
                    handleChange={(newContent) => {
                      console.log("📝 New Content:", newContent);
                      setData((prevData) => ({
                        ...prevData,
                        description: newContent.target.value,
                      }));
                    }}
                  />
                  {errors.description && (
                    <p className="text-danger">{errors.description}</p>
                  )}
                </div>

                <div
                  className="col-12 d-flex justify-content-center"
                  style={{ marginTop: "20px" }}
                >
                  <button
                    className="btn btn-outline-info wd-100"
                    onClick={() => navigate(-1)}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-info wd-100 ms-2"
                    disabled={buttonDisabled}
                    onClick={saveMaterial}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default EditFundamentalMaterial;
