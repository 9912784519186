import { API_PATHS } from "../utils/constants/api.constants";
import axiosInstance from "../utils/authUtils";

class fundamentalService {
  static postfundmentalsubjects(item) {
    return axiosInstance.post(API_PATHS.addfundsubjects, item);
  }
  static getfundmentalsubjects(item) {
    let api;
    
      api = `${API_PATHS.getfundsubjects}`;
   

    return axiosInstance.get(api);
  }

  static getfundamentalSubjectsDetails(id) {
    let api;
    
      api = `${API_PATHS.getfundsubjects}?id=${id}`;
   

    return axiosInstance.get(api);
  }

  static editfundmentalsubjects(item) {
    return axiosInstance.put(API_PATHS.editfundsubjects, item);
  }

  static deletefundmentalsubjects(id) {
    return axiosInstance.delete(`${API_PATHS.deletefundsubjects}?id=${id}`);
  }

  //fundamental units
  static postfundmentalunits(item) {
    return axiosInstance.post(API_PATHS.addfundamentalunits, item);
  }

  static getfundmentalunits(item) {
    let api;
      api = `${API_PATHS.getfundamentalunits}?chapterId=${item.id}`;

    return axiosInstance.get(api);
  }

  static getfundmentalunitsDetails(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getfundamentalunits}?id=${id}`;
    } else {
      api = `${API_PATHS.getfundamentalunits}`;
    }

    return axiosInstance.get(api);
  }

  static editfundmentalunits(item) {
    return axiosInstance.put(API_PATHS.editfundamentalunits, item);
  }

  static deletefundmentalunits(id) {
    return axiosInstance.delete(`${API_PATHS.deletefundamentalunits}?id=${id}`);
  }

  static getFundamentalChapters(item) {
    let api;
      api = `${API_PATHS.getFundamentalChapters}?subjectId=${item.id}`;
      return axiosInstance.get(api);
  }

 
  static deleteFundamentalChapters(id) {
    return axiosInstance.delete(
      `${API_PATHS.deleteFundamentalChapters}?id=${id}`
    );
  }

  static postfundmentalmaterial(item) {
    return axiosInstance.post(API_PATHS.addfundamentalmaterial, item);
  }

  static getfundmentalmaterial(item) {
    let api;
        
    api = `${API_PATHS.getfundamentalmaterial}?unitId=${item.id}`;
  return axiosInstance.get(api);
  }

  static getsinglefundmentalmaterial(item) {
    let api;

    api = `${API_PATHS.getfundamentalmaterial}?unitId=${item.id1}&id=${item.id2}`;
    return axiosInstance.get(api);
  }

  static editfundmentalmaterial(item) {
    return axiosInstance.put(API_PATHS.editfundamentalmaterial, item);
  }

  static deletefundmentalmaterial(id) {
    return axiosInstance.delete(
      `${API_PATHS.deletefundamentalmaterial}?id=${id}`
    );
  }

  static getFundamentalPretest(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getFundamentalPretest}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getFundamentalPretest}`;
    }

    return axiosInstance.get(api);
  }

  static postAddFundamentalPretest(item) {
    return axiosInstance.post(API_PATHS.postAddFundamentalPretest, item);
  }

  static editFundamentalPretest(item) {
    return axiosInstance.put(API_PATHS.editFundamentalPretest, item);
  }

  static getFundamentalPretestByUnitId(item) {
    let api;

    api = `${API_PATHS.getFundamentalPretest}?unitId=${item.id1}&id=${item.id2}`;

    return axiosInstance.get(api);
  }

  static getFundamentalChaptersByChapterId(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getFundamentalChapters}?id=${id}`;
    } else {
      api = `${API_PATHS.getFundamentalChapters}`;
    }
    return axiosInstance.get(api);
  }

  static deleteFundamentalPretest(id) {
    return axiosInstance.delete(
      `${API_PATHS.deleteFundamentalPretest}?id=${id}`
    );
  }

  static postFundmentalChapters(item) {
    return axiosInstance.post(API_PATHS.addFundamentalChapters, item);
  }

  static editFundamentalChapters(item) {
    return axiosInstance.put(API_PATHS.editFundamentalChapters, item);
  }

  //fundamental finaltest services
  static postfundmentalfinaltests(item) {
    return axiosInstance.post(API_PATHS.addfundamentalfinaltest, item);
  }

  static postmorefundmentalfinaltests(item) {
    return axiosInstance.post(API_PATHS.addmorefundamentalfinaltest, item);
  }

  static getfundmentalfinaltest(id) {
    let api;
    if (id) {
      api = `${API_PATHS.getfundamentalfinaltests}?unitId=${id}`;
    } else {
      api = `${API_PATHS.getfundamentalfinaltests}`;
    }

    return axiosInstance.get(api);
  }

  static getsinglefundmentalfinaltests(item) {
    let user = JSON.parse(localStorage.getItem("user"));
    let api;

    api = `${API_PATHS.getfundamentalfinaltests}?unitId=${item.id1}&id=${item.id2}`;

    return axiosInstance.get(api);
  }

  static editfundmentalfinaltest(item) {
    return axiosInstance.put(API_PATHS.editfundamentalfinaltest, item);
  }

  static deletefundmentalfinaltest(id) {
    return axiosInstance.delete(
      `${API_PATHS.deletefundamentalfinaltest}?id=${id}`
    );
  }
}

export default fundamentalService;
