import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { CiCirclePlus, CiCircleMinus } from "react-icons/ci";
import {
  getfundmentalunitsdetails,
  postfundamentalFinaltest,
} from "../../../store/slices/fundamentals";
import { useEffect } from "react";
import { Logout } from "../../../utils/helpers/Logout";

function AddFundamentalFinalTest() {
  const data1 = JSON.parse(localStorage.getItem("units"));
  const [data2, setData2] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [unitData, setUnitData] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    getUnitDetails();
  }, []);

  const getUnitDetails = () => {
    dispatch(getfundmentalunitsdetails(id))
      .unwrap()
      .then((data) => {
        setUnitData(data?.fundamentalunits?.data[0] || {});
      })
      .catch(({ message }) => alert(message));
  };

  // Default question structure
  const defaultQuestion = (questionNumber) => ({
    type: "text",
    question: "",
    correctOption: "",
    explanation: "",
    questionNumber,
    options: Array.from({ length: 4 }, (_, i) => ({
      optionNumber: (i + 1).toString(),
      optionValue: "",
      correctOption: false,
    })),
  });

  // Initial state for 5 questions
  const [questions, setQuestions] = useState(
    Array.from({ length: 1 }, (_, i) => defaultQuestion(i + 1))
  );

  const [errors, setErrors] = useState(
    questions.map(() => ({
      question: "",
      explanation: "",
      correctOption: "",
      options: Array.from({ length: 4 }, () => ({
        optionNumber: "",
        optionValue: "",
        validationMessage: "",
      })),
    }))
  );

  // Handles question and explanation changes
  const handleQuestionChange = (e, questionIndex) => {
    const { name, value } = e.target;

    setQuestions((prevQuestions) =>
      prevQuestions.map((question, index) =>
        index === questionIndex ? { ...question, [name]: value } : question
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, index) =>
        index === questionIndex
          ? {
              ...error,
              [name]: value.trim() ? "" : "This field cannot be empty",
            }
          : error
      )
    );
  };

  // Handles option changes
  const handleOptionChange = (e, questionIndex, optionIndex) => {
    const { name, value, checked } = e.target;

    setQuestions((prevQuestions) =>
      prevQuestions.map((question, qIdx) =>
        qIdx === questionIndex
          ? {
              ...question,
              options: question.options.map((option, oIdx) =>
                oIdx === optionIndex
                  ? {
                      ...option,
                      [name]: name === "correctOption" ? checked : value,
                    }
                  : option
              ),
              correctOption:
                name === "correctOption" && checked
                  ? optionIndex + 1
                  : question.correctOption,
            }
          : question
      )
    );

    setErrors((prevErrors) =>
      prevErrors.map((error, qIdx) =>
        qIdx === questionIndex
          ? {
              ...error,
              options: error.options.map((optError, oIdx) =>
                oIdx === optionIndex
                  ? {
                      ...optError,
                      [name]: value.trim() ? "" : "This field cannot be empty",
                    }
                  : optError
              ),
            }
          : error
      )
    );
  };

  // Adds a new question
  const handleAddMainForms = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      defaultQuestion(prevQuestions.length + 1),
    ]);

    setErrors((prevErrors) => [
      ...prevErrors,
      {
        question: "",
        explanation: "",
        correctOption: "",
        options: Array.from({ length: 4 }, () => ({
          optionNumber: "",
          optionValue: "",
          validationMessage: "",
        })),
      },
    ]);
  };

  // Deletes a question
  const handleDeleteQuestion = (questionIndex) => {
    setQuestions((prevQuestions) =>
      prevQuestions
        .filter((_, index) => index !== questionIndex)
        .map((q, i) => ({ ...q, questionNumber: i + 1 }))
    );

    setErrors((prevErrors) =>
      prevErrors.filter((_, index) => index !== questionIndex)
    );
  };

  // Validates before submission
  const validateQuestions = () => {
    let isValid = true;

    const updatedErrors = questions.map((question) => {
      const isAnyOptionSelected = question.options.some(
        (opt) => opt.correctOption
      );
      const isValidOptions =
        question.options.filter((opt) => opt.optionNumber && opt.optionValue)
          .length >= 2;

      const questionError = {
        question: question.question.trim() ? "" : "Please enter a question",
        explanation: question.explanation.trim()
          ? ""
          : "Please enter an explanation",
        correctOption: isAnyOptionSelected
          ? ""
          : "Select at least one correct option",
        options: question.options.map((option) => ({
          optionNumber: option.optionNumber ? "" : "Enter option number",
          optionValue: option.optionValue ? "" : "Enter option text",
        })),
      };

      if (!isAnyOptionSelected || !isValidOptions) {
        isValid = false;
      }

      return questionError;
    });

    setErrors(updatedErrors);
    return isValid;
  };

  // Save and send API request
  const save = (e) => {
    e.preventDefault();

    if (!validateQuestions()) {
      alert("Please fill all required fields correctly.");
      return;
    }

    const formattedQuestions = questions.map((q) => ({
      question: q.question,
      questionNumber: q.questionNumber,
      explanation: q.explanation,
      correctOption: q.correctOption,
      options: q.options.filter((opt) => opt.optionNumber && opt.optionValue),
    }));

    const qitem = {
      unitId: id,
      questions: formattedQuestions,
    };

    setButtonDisabled(true);
    dispatch(postfundamentalFinaltest(qitem))
      .unwrap()
      .then((res) => {
        alert(res.finaltest.message);
        navigate(`/fundamentals/finaltest/${id}`);
      })
      .catch(({ message }) => alert(message))
      .finally(() => setButtonDisabled(false));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    getUnits();
  }, []);

  const getUnits = () => {
    dispatch(getfundmentalunitsdetails(id))
      .unwrap()
      .then((data) => {
        setData2(data?.fundamentalunits?.data[0]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const cancelHandler = () => {
    navigate(`/fundamentals/finaltest/${id}`);
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home </Link>&#8811;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &#8811;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &#8811;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units </Link>
              &#8811;
              <Link to={`/fundamentals/finaltest/${id}`}>Final Test</Link>
              &#8811; Add Final Test
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                  <h4 class="card-title">
                    {data1?.subjectId?.subjectName} &nbsp;&#8811;&nbsp;
                    {data1?.chapterId?.chapterName} &nbsp;&#8811;&nbsp;{" "}
                    {data2?.unitName}
                  </h4>
                  &nbsp;
                  <div className="container-buttons1">
                    <div className="col-12 d-flex justify-content-end mt-1">
                      <button
                        className="btn btn-primay btn-sm"
                        style={{ height: "30px", width: "60px" }}
                        onClick={(e) => handleAddMainForms(e)}
                      >
                        <CiCirclePlus style={{ fontSize: "20px" }} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <section className="form-Questionnaire p-4">
                {questions.map((question, qindex) => (
                  <form key={qindex}>
                    <span className="bread-head1 mt-3">
                      Question No - {qindex + 1}
                    </span>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm p-1 "
                      onClick={() => handleDeleteQuestion(qindex)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <CiCircleMinus
                        style={{ fontSize: "20px", color: "red" }}
                      />
                    </button>
                    <div className="row">
                      <div className="col-md-11 col-sm-12 pt-2">
                        <div className="question-explanation-section">
                          <textarea
                            className="form-control"
                            name="question"
                            placeholder="Enter your question"
                            onChange={(e) => handleQuestionChange(e, qindex)}
                            value={question.question}
                          />
                        </div>
                        <p className="alert-message">
                          {errors[qindex]?.question}
                        </p>
                      </div>
                      <div className="col-md-11 col-sm-12 pt-2">
                        <div className="question-explanation-section">
                          <span className="bread-head1 mt-3">Explanation</span>
                          <textarea
                            className="form-control"
                            name="explanation"
                            placeholder="Enter explanation"
                            onChange={(e) => handleQuestionChange(e, qindex)}
                            value={question.explanation}
                          />
                        </div>
                        <p className="alert-message">
                          {errors[qindex]?.explanation}
                        </p>
                      </div>

                      <div className=" d-flex col-md-4 col-sm-12 pt-2 pb-1 col-ml-5">
                        <span className="bread-head1 ">Correct option :</span>
                        <span className="bread-head1 ">
                          {questions[qindex]?.correctOption}
                        </span>
                      </div>

                      <div className="row mt-2">
                        <div className="col-6">
                          <span className="bread-head1">Options</span>
                        </div>
                      </div>
                      <div style={{ paddingBottom: "30px" }}>
                        <div className="row option-section ">
                          {question.options.map((option, optionIndex) => (
                            <div className="col-12" key={optionIndex}>
                              <div className="row">
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={`Option No - ${
                                      optionIndex + 1
                                    }`}
                                    value={option.optionNumber}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="optionNumber"
                                  />
                                  <p className="alert-message">
                                    {option.optionNumber !== "" && (
                                      <span>
                                        {
                                          errors[qindex]?.options[optionIndex]
                                            ?.optionNumber
                                        }
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-6 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={`Enter option ${
                                      optionIndex + 1
                                    }`}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    value={option.optionValue}
                                    name="optionValue"
                                    required
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.optionValue
                                      : ""}
                                  </p>
                                </div>

                                <div
                                  style={{ height: "100%" }}
                                  className="col-md-2 col-sm-12 pt-2 pb-1"
                                >
                                  <input
                                    type="checkbox"
                                    className="mt-2"
                                    label=""
                                    checked={option.correctOption}
                                    onChange={(e) =>
                                      handleOptionChange(e, qindex, optionIndex)
                                    }
                                    name="correctOption"
                                    id={`correctOption-${qindex}-${optionIndex}`}
                                    disabled={
                                      !option.optionNumber.trim() ||
                                      !option.optionValue.trim()
                                    }
                                  />
                                  <p className="alert-message">
                                    {optionIndex < 2
                                      ? errors[qindex]?.options[optionIndex]
                                          ?.validationMessage
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                ))}
              </section>

              <div className="container-buttons">
                <div className="col-12 d-flex  justify-content-center p-2">
                  <button
                    className="btn btn-outline-info wd-100 "
                    onClick={cancelHandler}
                    disabled={buttonDisabled}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-info wd-100 ms-2"
                    disabled={buttonDisabled}
                    onClick={save}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}

export default AddFundamentalFinalTest;
