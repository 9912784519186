import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postfundamentalMaterial } from "../../../store/slices/fundamentals";
import TextEditor from "./Editor";
import { Logout } from "../../../utils/helpers/Logout";

const AddFundamentalMaterial = () => {
  const { id } = useParams();
  localStorage.setItem("unitId", id);
  let unitId = localStorage.getItem("unitId");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [textError, setTextError] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const data1 = JSON.parse(localStorage.getItem("units"));
  const location = useLocation();
  const data2 = location.state || {};
  const [data, setData] = useState({
    materialName: "",
    materialNumber: "",
    description: "",
    url: "",
    unitId: unitId,
    status: "active",
    type: "pdf",
  });
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    setEditorLoaded(true);
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "materialName":
        const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
        value = filteredValue;
        setNameError(
          filteredValue === ""
            ? "Please enter material name"
            : /^[a-zA-Z0-9\s&,'\-]*$/.test(filteredValue)
            ? ""
            : "Invalid characters in material name"
        );
        break;

      case "materialNumber":
        setNumberError(
          value === "" || value <= 0
            ? "Please enter a valid material number greater than 0"
            : ""
        );
        break;

      case "description":
        setTextError(value.trim() === "" ? "Please enter description" : "");
        break;

      default:
        break;
    }

    setData({
      ...data,
      [name]: value,
    });
  };

  const save = (e) => {
    e.preventDefault();

    let errors = false;

    // Validate material name
    if (data.materialName.trim() === "") {
      setNameError("Please enter material name");
      errors = true;
    } else {
      setNameError("");
    }

    // Validate material number
    if (data.materialNumber === "" || data.materialNumber <= 0) {
      setNumberError("Please enter a valid material number greater than 0");
      errors = true;
    } else {
      setNumberError("");
    }

    // Validate description
    if (data.description.trim() === "") {
      setTextError("Please enter description");
      errors = true;
    } else {
      setTextError("");
    }

    // Stop submission if any error exists
    if (errors) return;

    upload(e);
  };

  const upload = (e) => {
    e.preventDefault();
    let item = data;
    setButtonDisabled(true);

    dispatch(postfundamentalMaterial(item))
      .unwrap()
      .then((res) => {
        console.log(res);
        if (res.fundamentalmatrial.data) {
          alert(res.fundamentalmatrial.message);
          navigate("/fundamentals/materials/" + unitId);
        }
        setButtonDisabled(false);
      })
      .catch(({ message }) => {
        setButtonDisabled(false);
        alert(message);
      });
  };

  const cancelHandler = () => {
    navigate(`/fundamentals/materials/${id}`);
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/home">Home </Link>&#8811;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
              &#8811;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>
              &#8811;
              <Link to={`/fundamentals/units/${fundunitid}`}>Units </Link>
              &#8811;
              <Link to={`/fundamentals/materials/${id}`}>Material </Link>
              &#8811; Add Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">
                    {" "}
                    {data1?.subjectId?.subjectName} &nbsp;&#8811;&nbsp;
                    {data1?.chapterId?.chapterName}&nbsp;&#8811;&nbsp;{" "}
                    {data2?.unitId?.unitName}
                  </h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Name:</label>
                      <input
                        type="text"
                        name="materialName"
                        className="form-control"
                        placeholder="Topic Name"
                        value={data.materialName}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Number:</label>
                      <input
                        type="number"
                        name="materialNumber"
                        className="form-control"
                        placeholder="Topic Number"
                        onChange={handleChange}
                        value={data.materialNumber}
                      />
                      <p className="alert-message">{numberError}</p>
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12 mt-2">
                    <TextEditor
                      data={data}
                      setData={setData}
                      handleChange={handleChange}
                    />

                    <p className="alert-message">{textError}</p>
                  </div>

                  <div
                    className="col-12 d-flex  justify-content-center"
                    style={{ marginTop: "60px" }}
                  >
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      disabled={buttonDisabled}
                      onClick={save}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default AddFundamentalMaterial;
