// for getiing user bank details
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fundamentalService from "../../services/fundamental.service"


export const postfundamentalSubjects = createAsyncThunk(
    "/post/fundamentalsubjects",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postfundmentalsubjects(item);
        return { fundamentalsubjects: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const editfundamentalSubjects = createAsyncThunk(
    "/edit/fundamentalsubjects",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editfundmentalsubjects(item);
        return { fundamentalsubjects: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getfundamentalSubjects = createAsyncThunk(
    "/get/fundamentalsubjects",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundmentalsubjects(item);
        return { fundamentalsubjects: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getfundamentalSubjectsDetails = createAsyncThunk(
    "/get/fundamentalsubjects",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundamentalSubjectsDetails(id);
        return { fundamentalsubjects: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deletefundamenntalSubjects = createAsyncThunk(
    "/delete/fundamentalsubjects",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deletefundmentalsubjects(id);
        return { fundamentalsubjects: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  //  fundamental units
export const postfundamentalUnits = createAsyncThunk(
    "/post/fundamentalunits",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postfundmentalunits(item);
        return { fundamentalunits: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const editfundamentalUnits = createAsyncThunk(
    "/edit/fundamentalunits",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editfundmentalunits(item);
        return { fundamentalunits: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getfundamentalUnits = createAsyncThunk(
    "/get/fundamentalunits",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundmentalunits(item);
        return { fundamentalunits: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  
  export const getfundmentalunitsdetails = createAsyncThunk(
    "/get/getfundmentalunitsdetails",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundmentalunitsDetails(id);
        return { fundamentalunits: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  

  export const deletefundamentalUnits = createAsyncThunk(
    "/delete/fundamentalunits",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deletefundmentalunits(id);
        return { fundamentalunits: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getFundamentalChapters = createAsyncThunk(
    "/get/fundamentals",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getFundamentalChapters(item);
        return {chapters:data?.data};
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const deleteFundamentalChapters = createAsyncThunk(
    "/delete/fundamentalchapters",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deleteFundamentalChapters(id);
        return { chapters: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getFundamentalChaptersByChapterId = createAsyncThunk(
    "/get/fundamentals/chapterId",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getFundamentalChaptersByChapterId(id);
        return {chapters:data?.data};
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const postFundmentalChapters = createAsyncThunk(
    "/post/fundamentalchapters",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postFundmentalChapters(item);
        return { chapters: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const editFundamentalChapters = createAsyncThunk(
    "/post/fundamentalchapters",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editFundamentalChapters(item);
        return { chapters: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  //for fundamental material
  export const postfundamentalMaterial = createAsyncThunk(
    "/post/fundamentalmaterial",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postfundmentalmaterial(item);
        return { fundamentalmatrial: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


  export const editfundamentalMaterial = createAsyncThunk(
    "/edit/fundamentalmaterial",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editfundmentalmaterial(item);
        return { fundamentalmatrial: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getfundamentalMaterials = createAsyncThunk(
    "/get/fundamentalmaterials",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundmentalmaterial(item);
        return { fundamentalmatrial: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  

  export const getsinglefundamentalMaterials = createAsyncThunk(
    "/get/singlefundamentalmaterials",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getsinglefundmentalmaterial(item);
        return { fundamentalmatrial: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deletefundamenntalMaterial = createAsyncThunk(
    "/delete/fundamentalmaterial",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deletefundmentalmaterial(id);
        return { fundamentalmatrial: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getFundamentalPretest = createAsyncThunk(
    "/get/pretest",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getFundamentalPretest(id);
        return { pretest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getFundamentalPretestByUnitId = createAsyncThunk(
    "/get/fundamentalunits/pretest",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getFundamentalPretestByUnitId(id);
        return {pretest:data?.data};
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );
  export const editFundamentalPretest = createAsyncThunk(
    "/edit/fundamentalpretest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editFundamentalPretest(item);
        return { pretest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const postAddFundamentalPretest = createAsyncThunk(
    "/post/addfundamentalpretest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postAddFundamentalPretest (item);
        return { pretest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


   //for fundamental finaltest
   export const postfundamentalFinaltest = createAsyncThunk(
    "/post/fundamentalfinaltest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postfundmentalfinaltests(item);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );


   //for fundamental finaltest
   export const postmorefundamentalFinaltest = createAsyncThunk(
    "/post/morefundamentalfinaltest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.postmorefundmentalfinaltests(item);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deleteFundamentalPretest = createAsyncThunk(
    "/delete/fundamentalpretest",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deleteFundamentalPretest(id);
        return { pretest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const editfundamentalFinaltest = createAsyncThunk(
    "/edit/fundamentalfinaltest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.editfundmentalfinaltest(item);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const getfundamentalFinaltest = createAsyncThunk(
    "/get/fundamentalfinaltests",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.getfundmentalfinaltest(id);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  

  export const getsinglefundamentalFinalTest = createAsyncThunk(
    "/get/singlefundamentalfinaltest",
    async (item, thunkAPI) => {
      try {
        const data = await fundamentalService.getsinglefundmentalfinaltests(item);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

  export const deletefundamenntalFinaltests = createAsyncThunk(
    "/delete/fundamentalfinaltest",
    async (id, thunkAPI) => {
      try {
        const data = await fundamentalService.deletefundmentalfinaltest(id);
        return { finaltest: data?.data };
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue({ message });
      }
    }
  );

const initialState = {
    loading: false,
    error: "",
    fundamentalsubjects: [] || null,
    fundamentalunits:[]|| null,
    isLoggedIn: false,
};

const fundamentalsubjects = createSlice({
    name: "fundamentalsubjects",
    initialState,
    extraReducers: {
       
     
        // [logout.fulfilled]: (state) => {
        //     state.isLoggedIn = false;
        //     state.user = null;
        // },
    },
});

const { reducer } = fundamentalsubjects;
export default reducer;




  


