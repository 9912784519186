import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Container, Col, Table } from "reactstrap";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import {
  deletefundamenntalFinaltests,
  getfundamentalFinaltest,
  getfundmentalunitsdetails,
} from "../../../store/slices/fundamentals";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import ReactPaginate from "react-paginate";

const FundamentalFinalTests = () => {
  const { id } = useParams();
  const [data, setData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [totaldata, setTotaldata] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const data1 = JSON.parse(localStorage.getItem("units"));
  const [data2, setData2] = useState();

  const itemsPerPage = 10;
  const pagesToShowInitially = 3;
  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1 || currentPage;
    setCurrentPage(newPage);
    search.set("page", newPage);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    getUnits();
  }, []);

  const handleEditClick = () => {
    navigate(`/fundamentals/edit-questions/${id}`);
  };
  const handleAddFinaltest = () => {
    navigate(`/fundamentals/add-questions/${id}`);
  };

  useEffect(() => {
    GetFundamentalFinaltests();
  }, [itemsPerPage, currentPage]);

  const GetFundamentalFinaltests = () => {
    setLoading(true);
    dispatch(getfundamentalFinaltest(id))
      .unwrap()
      .then((data) => {
        console.log({ data });
        setData(data.finaltest.data);
        setTotaldata(data?.finaltest?.totalDataCount);
        setDeleteId(data?.finaltest?.data[0]?._id);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((err) => {});
  };

  const getUnits = () => {
    dispatch(getfundmentalunitsdetails(id))
      .unwrap()
      .then((data) => {
        setData2(data.fundamentalunits.data[0]);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };
  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleDelete = () => {
    dispatch(deletefundamenntalFinaltests(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.finaltest.message);
        GetFundamentalFinaltests();
        setShowModal(false);
      })
      .catch((errr) => {});
  };

  const fundchapterid = localStorage.getItem("fundmentalchapterId");
  const fundunitid = localStorage.getItem("fundmentalunitId");
  const handleAddMoreSeriesClick = () => {
    navigate(`/addmorequestion/${id}`);
  };
  return (
    <>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={handleDelete}
      />
      <div>
        <React.Fragment>
          <div className="page-content">
            <Container fluid={true}>
              <div
                class="text-start my-2 ms-1"
                style={{
                  fontWeight: "600",
                }}
              >
                <Link to="/home">Home</Link>&nbsp;&#8811;&nbsp;
                <Link to="/fundamentals/subjects">Fundamental Subjects</Link>
                &nbsp;&#8811;&nbsp;
                <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                  Chapters
                </Link>
                &nbsp;&#8811;&nbsp;
                <Link to={`/fundamentals/units/${fundunitid}`}>Units</Link>
                &nbsp;&#8811;&nbsp;Final Test
              </div>
              <div class="card">
                <div class="card-header">
                  <div class="card-title-wrap bar-success d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                      <h4 class="card-title">
                        {data1?.subjectId?.subjectName} &nbsp;&#8811;&nbsp;
                        {data1?.chapterId?.chapterName} &nbsp;&#8811;&nbsp;{" "}
                        {data2?.unitName}
                      </h4>
                      {!data?.length ? (
                        <button
                          className="btn btn-primary btn-sm m-1"
                          onClick={handleAddFinaltest}
                        >
                          Add Questions
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary btn-sm ms-2"
                          onClick={handleAddMoreSeriesClick}
                        >
                          Add More Questions
                        </button>
                      )}
                    </div>
                    {data?.length > 0 && (
                      <div className="td">
                        <button
                          className="btn btn-outline-info btn-sm"
                          onClick={handleEditClick}
                        >
                          Edit
                        </button>
                        <button
                          className="btn btn-outline-danger btn-sm ms-2"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div class="card-body collapse show">
                  <div class="card-block card-dashboard table-responsive">
                    {loading ? (
                      <Col lg={12} xs={12} md={12} align="center">
                        {ShowSkeleton()}
                      </Col>
                    ) : !data?.length ? (
                      <div className="mt-4 text-center card-body collapse show pb-3">
                        <h5> No questions were added to this unit</h5>
                      </div>
                    ) : (
                      <Table responsive hover style={{ overflowX: "scroll" }}>
                        <thead>
                          <tr>
                            <th className="text-center align-middle">
                              Question No
                            </th>
                            <th className="text-center align-middle">
                              Question
                            </th>
                            <th className="text-center align-middle">
                              Options
                            </th>
                            <th className="text-center align-middle">
                              Correct Option
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item, index) =>
                            item?.questions?.map((question, ind) => (
                              <tr key={ind}>
                                <td className="text-center align-middle">
                                  {question.questionNumber}
                                </td>
                                <td className="text-center align-middle">
                                  {question.question}
                                </td>
                                <td>
                                  {question.options.map((elem) => (
                                    <p className="text-center align-middle">
                                      {elem.optionNumber}-{elem.optionValue}
                                    </p>
                                  ))}
                                </td>
                                <td className="text-center align-middle">
                                  {question.correctOption}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    )}
                  </div>

                  <div className="mr-5">
                    {totaldata / itemsPerPage > 1 && (
                      <div className="mt-5 d-flex justify-content-end align-right">
                        <ReactPaginate
                          key={currentPage}
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakLinkClassName={"page-link"}
                          pageCount={Math.ceil(totaldata / itemsPerPage)}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={pagesToShowInitially}
                          onPageChange={handlePageChange}
                          containerClassName="pagination"
                          activeClassName="active"
                          pageLinkClassName="page-link"
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          disabledClassName="disabled"
                          initialPage={currentPage - 1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
export default FundamentalFinalTests;
