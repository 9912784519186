export const API_BASE_URL =
  

process.env.API_BASE_URL || "https://api.learningmastery.in/api/v1";
// process.env.API_BASE_URL || "https://life.octaloptimum.in/api/v1/";

export const API_PATHS = {
    //auth
    login: "/admin/login",
    register: "/register",

//current affairs
    postcurrentaffais :"/admin/currentAffairs/add",
   getCurrentAffairs :"/admin/currentAffairs/fetch",
   editCurrentAffairs :"/admin/currentAffairs/edit",
   deletcurrentAffairs :"/admin/currentAffairs/delete",

   //fundamental chapters
   getFundamentalChapters:"/admin/fundamental-chapter/fetch",
   addFundamentalChapters:"/admin/fundamental-chapter/add",
   editFundamentalChapters:"/admin/fundamental-chapter/edit",
   deleteFundamentalChapters:"/admin/fundamental-chapter/delete",

   //fundamental subjects
getfundsubjects :"/admin/fundamental-subject/fetch",
addfundsubjects :"/admin/fundamental-subject/add",
editfundsubjects :"/admin/fundamental-subject/edit",
deletefundsubjects :"/admin/fundamental-subject/delete",

//fundamental units
getfundamentalunits :"/admin/fundamental-units/fetch",
addfundamentalunits :"/admin/fundamental-units/add",
editfundamentalunits :"/admin/fundamental-units/edit",
deletefundamentalunits :"/admin/fundamental-units/delete",

//fundamental material
getfundamentalmaterial :"/admin/fundamental-material/fetch",
addfundamentalmaterial :"/admin/fundamental-material/add",
editfundamentalmaterial :"/admin/fundamental-material/edit",
deletefundamentalmaterial :'/admin/fundamental-material/delete',

//fundamental final test
getfundamentalfinaltests:"/admin/assessment/fundamental-finaltest/fetch",
addfundamentalfinaltest :"/admin/assessment/fundamental-finaltest/add",
editfundamentalfinaltest :"/admin/assessment/fundamental-finaltest/edit",
deletefundamentalfinaltest :"/admin/assessment/fundamental-finaltest/delete",
addmorefundamentalfinaltest :"/admin/assessment/fundamental-finaltest/addmore-question",

//file upload
fileUpload :`${API_BASE_URL}/admin/upload`,

//get course
getcourses :"/admin/course/fetch",
addcourse:"/admin/course/add",
editcourseDetails:"admin/course/edit",
deletecourse:"/admin/course/delete",

 

//course subjects 
getcoursesubjects :"/admin/subject/fetch",
addcoursesubject :"/admin/subject/add",
editcoursesubjectDetails:"admin/subject/edit",
deletecoursesubject :"/admin/subject/delete",

//faq
addFaq:'/admin/faq/add',
getFaq:'/admin/faq/fetch',
editFaq:'/admin/faq/edit',
deleteFaq:'/admin/faq/delete',

//user
getPaid:'/admin/users/fetch',
getpaidUserDetails:"/admin/users/completed-profiles/fetch",
getUnPaid:'/admin/users/fetch',

//payment
getPayment:'/admin/userpayments/fetch',
getActivity:'/admin/userActivities/fetch',
getuserstats: "/admin/fetch-user-stats",

//combinations
getcombinations :"/admin/combination/fetch",
addcombinations :"/admin/combination/add",
editcombinations:"admin/combination/edit",
deletecombinations :"/admin/combination/delete",


//chapters
getchapters:"/admin/chapter/fetch",
addchapters :"/admin/chapter/add",
editchapters:"admin/chapter/edit",
deletechapters :"/admin/chapter/delete",

//units
getunits :"/admin/units/fetch",
addunits :"/admin/units/add",
editunits:"admin/units/edit",
deleteunits :"/admin/units/delete",

//plan
addplan :'/admin/plans/add',
getplan :"/admin/package/fetch",
editplan :"/admin/plans/edit",
deleteplan :"/admin/plans/delete",

//materials
addmaterials :'/admin/material/add',
getmaterials :"/admin/material/fetch",
editmaterials :"/admin/material/edit",
deletematerials :"/admin/material/delete",


//finaltest
addfinaltests :'/admin/assessment/finaltest/add',
getfinaltests :"/admin/assessment/finaltest/fetch",
editfinaltests :"/admin/assessment/finaltest/edit",
deletefinaltets :"/admin/finaltests/delete",
addmorequestion:"/admin/assessment/finaltest/addmorequestion",


 //issues
 getTicketsList :"/admin/tickets-list/fetch",
closeTicket :"/admin/closeTicket",
getTicketStatistics :"/admin/ticket/statistics",
getIssues :"/admin/issues/fetch",
addIssues :"/admin/issues/add",
editIssues :"/admin/issues/edit",
deleteIssues :"/admin/issues/delete",

// Issues 
getSupportIssues:"/admin/issues/fetch",
getVisitorsList :"/admin/fetch/visitors-list",

//Series 
getSeries :"/admin/series/fetch",
addSeries :"/admin/series/add",
deleteSeries : "/admin/series/delete",
editSeries:"/admin/series/edit",

//TestSeries
getSeriesTest:"/admin/seriestests/fetch",
postSeriesTest:"/admin/seriestests/add",
editSeriesTest:"/admin/seriestests/edit",
deleteSeriesTest:"/admin/seriestests/delete",
AddMoreSeriesTest  :"/admin/seriestests/addQuestions",

//Quiz Category
getCategoryQuiz:"/admin/quizCategory/new/fetch",
deleteCategoryQuiz:"/admin/quizCategory/new/delete",
postCategoryQuiz:"/admin/quizCategory/new/add",
editCategoryQuiz:"/admin/quizCategory/new/edit",

// Quiz Content
getContentQuiz :"/admin/quizContent/new/fetch",
deleteContentQuiz :"/admin/quizContent/new/delete",
postContentQuiz:"/admin/quizContent/new/add",
editContentQuiz:"/admin/quizContent/new/edit",

//assesments
addAssessments :"/admin/assessment/add",
getAssessments:"/admin/assessment/fetch",
editAssessments :"/admin/assessment/edit",
deleteAssesments:"/admin/assessment/delete",

//challenges
addchallenges:"/admin/challenges/add",
getchallenges:"/admin/challenges/fetch",
editchallenges:"/admin/challenges/edit",
deletechallenges:"/admin/challenges/delete",

}