import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GetUnitDetails,
  editMaterial,
  getsingleMaterials,
} from "../../../store/slices/courses";
import FileUpload from "../../../utils/helpers/Fileupload";
import { Logout } from "../../../utils/helpers/Logout";
import ContentEditor from "../../Fundamentals/fundamentalMaterial/ContentEditor";
import { ShowSkeleton1 } from "../../../utils/helpers/Skeleton";

const EditMaterial = () => {
  const { id1, id2 } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [textError, setTextError] = useState("");
  const [urlError, setUrlError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState({});
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [breadData, setBreadData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
    fetchData1();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const fetchData1 = () => {
    dispatch(GetUnitDetails(id1))
      .unwrap()
      .then((data) => {
        setBreadData(data?.user?.data[0]);
      })
      .catch(({ message }) => {});
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "materialName":
        const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
        value = filteredValue;
        const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
        if (filteredValue === "") {
          setNameError("Please enter material name");
        } else if (!categorynameregex.test(filteredValue)) {
          setNameError("Invalid characters in material name");
        } else {
          setNameError("");
        }
        break;
      case "materialNumber":
        setNumberError(
          value === ""
            ? "Please enter material Number"
            : value <= 0
            ? "Please enter valid Number"
            : ""
        );
        value = value < 1 ? "" : value;
        break;
      case "materialUrl":
        setUrlError(value === null ? "Please enter Url" : "");
        break;
      case "description":
        setTextError(value === "" ? "Please enter description" : "");
        break;
      default:
        break;
    }
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  React.useEffect(() => {
    const item = {
      id1: id1,
      id2: id2,
    };

    dispatch(getsingleMaterials(item))
      .unwrap()
      .then((data) => {
        const initialData = data.material.data[0];
        setData({
          materialName: initialData?.materialName || "",
          materialNumber: initialData?.materialNumber || "",
          materialUrl: initialData?.materialUrl || "",
          description: initialData?.description || "",
        });
        setEditorLoaded(true);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  const save = (e) => {
    e.preventDefault();
    let isValid = true;
    if (data.materialName === "") {
      setNameError("Please enter material name");
      isValid = false;
    }

    if (data.materialNumber === "") {
      setNumberError("Please enter material number");
      isValid = false;
    }
    if (data.materialNumber <= 0) {
      setNumberError("Please enter valid number");
      isValid = false;
    }
    if (data.description === "" || data.description === "<p><br></p>") {
      setTextError("Please enter description");
      isValid = false;
    }
    if (!isValid) {
      return;
    } else {
      upload(e);
    }
  };

  const upload = () => {
    const item = {
      id: id2,
      materialName: data?.materialName,
      materialNumber: data.materialNumber,
      description: data?.description,
      materialUrl: data.materialUrl,
      unitId: id1,
      status: 1,
      type: "pdf",
    };
    dispatch(editMaterial(item))
      .unwrap()
      .then((res) => {
        if (res.material.data) {
          alert(res.material.message);
          navigate("/materials/" + id1);
        }
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const chapterId = localStorage.getItem("chapterId");
  const combinationId = localStorage.getItem("combinationId");
  const unitId = localStorage.getItem("unitId");
  const materialId = localStorage.getItem("materialId");

  const cancelHandler = () => {
    navigate(`/previewmaterial/${id1}/${id2}`);
  };

  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>&nbsp;&#8811;&nbsp;
              <Link to={`/combination/${combinationId}`}>Combinations</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/viewchapters/${combinationId}/${chapterId}`}>
                Chapters
              </Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/units/${unitId}`}>Units</Link>
              &nbsp;&#8811;&nbsp;{" "}
              <Link to={`/materials/${materialId}`}>Material</Link>
              &nbsp;&#8811;&nbsp;
              <Link to={`/previewmaterial/${id1}/${id2}`}>
                Material Details
              </Link>
              &nbsp;&#8811;&nbsp; Edit Material
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Material</h4>
                </div>
              </div>
              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Name:</label>
                      <input
                        type="text"
                        name="materialName"
                        className="form-control"
                        placeholder="Topic Name"
                        value={data.materialName}
                        onChange={handleChange}
                      />
                      <p className="alert-message">{nameError}</p>
                    </div>
                    <div
                      style={{ height: "100%" }}
                      className="col-md-6 col-sm-12 mt-2"
                    >
                      <label className="bread-head1">Topic Number:</label>
                      <input
                        type="string"
                        name="materialNumber"
                        className="form-control"
                        placeholder="Topic Number"
                        maxLength={3}
                        onInput={(e) => {
                          e.target.value = e?.target?.value
                            ?.replace(/[^0-9]/g, "")
                            .slice(0, 3);
                        }}
                        onChange={handleChange}
                        value={data.materialNumber}
                      />
                      <p className="alert-message">{numberError}</p>
                    </div>
                  </div>

                  {loading ? (
                    <ShowSkeleton1 />
                  ) : (
                    <div className="col-md-12 col-sm-12 mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <label className="bread-head1">
                          Topic Description:
                        </label>
                      </div>

                      <ContentEditor
                        data={data}
                        setData={setData}
                        handleChange={handleChange}
                      />

                      <p className="alert-message">{textError}</p>
                    </div>
                  )}

                  <div
                    className="col-12 d-flex justify-content-center "
                    style={{ marginTop: "60px" }}
                  >
                    <button
                      className="btn btn-outline-info wd-100 "
                      onClick={cancelHandler}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info wd-100 ms-2"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default EditMaterial;
