import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Table } from "reactstrap";
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  deletefundamentalUnits,
  getfundamentalUnits,
} from "../../../store/slices/fundamentals";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function FundamentalUnits() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 10;

  const fundchapterid = localStorage.getItem("fundmentalchapterId");

  // ✅ Fetch data with correct pagination params
  const getUnits = () => {
    setLoading(true);
    dispatch(
      getfundamentalUnits({ limit: itemsPerPage, page: currentPage, id })
    )
      .unwrap()
      .then((data) => {
        if (data.fundamentalunits?.data?.length > 0) {
          localStorage.setItem(
            "units",
            JSON.stringify(data.fundamentalunits.data[0])
          );
        }
        setData(data.fundamentalunits.data || []);
      })
      .catch(({ message }) => alert(message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    Logout();
    localStorage.setItem("fundmentalunitId", id);
    getUnits();
  }, [currentPage, itemsPerPage, id]);

  const handleAddfundamentalUnits = () => {
    navigate(`/fundamentals/addunits/${id}`, { state: data[0] });
  };

  const DeleteUnits = () => {
    dispatch(deletefundamentalUnits(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.fundamentalunits.message);
        setShowModal(false);
        getUnits();
      })
      .catch(({ message }) => alert(message));
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteUnits}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div className="text-start my-2 ms-1" style={{ fontWeight: "600" }}>
              <Link to="/dashboard">Home</Link> &#8811;
              <Link to="/fundamentals/subjects">Fundamental Subjects</Link>{" "}
              &#8811;
              <Link to={`/fundamentals/chapters/${fundchapterid}`}>
                Chapters
              </Link>{" "}
              &#8811; Fundamental Units
            </div>
            <div className="card">
              <div className="card-header">
                <div className="card-title-wrap bar-success d-flex align-items-center">
                  <h4 className="card-title">
                    {data.length > 0
                      ? `${data[0]?.subjectId?.subjectName} ⟶ ${data[0]?.chapterId?.chapterName}`
                      : "No Data Available"}
                  </h4>
                  <button
                    className="btn btn-primary btn-sm ms-2 m-1"
                    onClick={handleAddfundamentalUnits}
                  >
                    Add Units
                  </button>
                </div>
              </div>
              <div className="card-body collapse show">
                <div className="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : data.length === 0 ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No Units Added!</h5>
                    </div>
                  ) : (
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th className="text-center">Unit Number</th>
                          <th className="text-center">Unit Name</th>
                          <th className="text-center">Details</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((val, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {val.unitNumber}
                            </td>
                            <td className="text-center align-middle">
                              {val.unitName}
                            </td>
                            <td className="text-center align-middle">
                              <Link to={`/fundamentals/materials/${val._id}`}>
                                <button className="btn btn-outline-primary btn-sm ms-2">
                                  Material
                                </button>
                              </Link>
                              <Link
                                to={`/fundamentals/finaltest/${val._id}`}
                                style={{ height: "25px", color: "white" }}
                              >
                                <button className="btn btn-outline-primary btn-sm ms-2">
                                  Final Test
                                </button>
                              </Link>
                            </td>
                            <td className="text-center align-middle">
                              <Link
                                to={`/fundamentals/units/edit/${id}/${val._id}`}
                                style={{ height: "25px", color: "white" }}
                              >
                                <button className="btn btn-outline-info btn-sm">
                                  <MdEdit className="viewbtn" />
                                </button>
                              </Link>
                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(val._id)}
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default FundamentalUnits;
