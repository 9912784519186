import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { editCourse, getCourseDetails } from "../../store/slices/courses";
import { useDispatch } from "react-redux";
import FileUpload from "../../utils/helpers/Fileupload";
import { Logout } from "../../utils/helpers/Logout";

function EditCourses() {
  const [courseName, setCourseName] = useState("");
  const { id2 } = useParams();
  const [buttonDisabled, setButtonDisabled] = useState();
  const dispatch = useDispatch();
  const [icon, setIcon] = useState("");
  const navigate = useNavigate("");
  const [courseError, setCourseError] = useState();
  const [iconError, setIconError] = useState();

  useEffect(() => {
    dispatch(getCourseDetails(id2))
      .unwrap()
      .then((data) => {
        setCourseName(data?.user?.data[0]?.courseName);
        setIcon(data?.user?.data[0]?.icon);
      })
      .catch(({ message }) => {
        alert(message);
      });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  const save = (e) => {
    if (courseName === "") {
      setCourseError("Please enter course name");
    }
    if (icon === "") {
      setIconError("Please upload icon");
    }
    if (!courseName || !icon) {
      return;
    } else {
      setIconError("");
      Editcourse(e);
    }
  };

  async function Editcourse(e) {
    e.preventDefault();
    setButtonDisabled((prevState) => !prevState);
    setTimeout(() => {
      setButtonDisabled((prevState) => !prevState);
    }, 4000);
    let status = 1;
    let type = "fulltime";
    let item = {
      courseName,
      id: id2,
      type,
      status,
      icon,
    };

    dispatch(editCourse(item))
      .unwrap()
      .then(() => {
        alert("Course updated succesfully");
        navigate(-1);
      })
      .catch(({ message }) => {
        alert(message);
        setButtonDisabled(false);
      });
  }

  const courseChange = (value) => {
    const filteredValue = value.replace(/[^a-zA-Z0-9\s&,'\-]/g, "");
    setCourseName(filteredValue);
    const categorynameregex = /^[a-zA-Z0-9\s&,'\-]*$/;
    if (!categorynameregex.test(filteredValue)) {
      setCourseError("Please enter valid name");
    } else {
      setCourseError("");
    }
  };

  const IconChange = async (e) => {
    try {
      const val = await FileUpload(e);
      setIcon(val);
      setIconError("");
    } catch (error) {
      alert("File Size is Large");
      setIconError("File Size is Large choose small size");

      // Handle error if needed
    }
  };

  const cancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link>&nbsp;&#8811;&nbsp;
              <Link to="/courses">Courses</Link>
              &nbsp;&#8811; Edit Course
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Edit Course</h4>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  <div className="row">
                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Course Name</span>
                      <input
                        type="text"
                        placeholder="Enter course name"
                        className="form-control"
                        value={courseName}
                        onChange={(e) => courseChange(e.target.value)}
                      />
                      <p className="alert-message">{courseError}</p>
                    </div>

                    <div
                      style={{ height: "100%" }}
                      className="col-md-5 pt-2 pb-2"
                    >
                      <span className="label">Icon</span>
                      <br />
                      <img src={icon} style={{ height: "80px" }} alt="" />
                      <br />
                      <br />
                      <input
                        type="file"
                        placeholder="Enter icon url link"
                        className="form-control"
                        onChange={IconChange}
                      />
                      <p className="alert-message">{iconError}</p>
                    </div>
                  </div>

                  <div className="col-md-10 mt-3" align="center">
                    <button
                      className="btn btn-outline-info wd-100"
                      onClick={cancelClick}
                      disabled={buttonDisabled}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-info ms-2 wd-100"
                      onClick={save}
                      disabled={buttonDisabled}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
}
export default EditCourses;
