import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Table } from "reactstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  deletefundamenntalSubjects,
  getfundamentalSubjects,
} from "../../../store/slices/fundamentals";
import DeleteModal from "../../../utils/helpers/Modals/DeleteModal";
import ShowSkeleton from "../../../utils/helpers/Skeleton";
import { Logout } from "../../../utils/helpers/Logout";
import { MdDelete, MdEdit } from "react-icons/md";

function FundamentalSubjects() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useSearchParams();
  const querypage = parseInt(search.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(querypage);
  const itemsPerPage = 25;

  const handleAddCurrentSubjects = () => {
    navigate("/fundamentals/addsubjects");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    Logout();
  }, []);

  useEffect(() => {
    getSubjects();
  }, [dispatch, itemsPerPage, currentPage]);

  const item = {
    limit: itemsPerPage,
    page: currentPage,
  };
  const getSubjects = () => {
    setLoading(true);
    dispatch(getfundamentalSubjects(item))
      .unwrap()
      .then((data) => {
        setData(data.fundamentalsubjects.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const DeleteSubects = () => {
    dispatch(deletefundamenntalSubjects(deleteId))
      .unwrap()
      .then((data) => {
        alert(data.fundamentalsubjects.message);
        setShowModal(false);
        getSubjects();
      })
      .catch(({ message }) => {
        alert(message);
      });
  };

  const handleDeleteClick = (id) => {
    setShowModal(true);
    setDeleteId(id);
  };

  return (
    <div>
      <DeleteModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleDelete={DeleteSubects}
      />
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <div
              class="text-start my-2 ms-1"
              style={{
                fontWeight: "600",
              }}
            >
              <Link to="/dashboard">Home</Link> &#8811; Fundamental Subjects
            </div>
            <div class="card">
              <div class="card-header">
                <div class="card-title-wrap bar-success d-flex align-items-center">
                  <h4 class="card-title">Fundamental Subjects</h4>
                  &nbsp;
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={handleAddCurrentSubjects}
                  >
                    Add Subjects
                  </button>
                </div>
              </div>

              <div class="card-body collapse show">
                <div class="card-block card-dashboard table-responsive">
                  {loading ? (
                    <Col lg={12} xs={12} md={12} align="center">
                      {ShowSkeleton()}
                    </Col>
                  ) : !data?.length ? (
                    <div className="mt-4 text-center card-body collapse show pb-3">
                      <h5>No Subjects were added </h5>
                    </div>
                  ) : (
                    <Table responsive hover style={{ overflowX: "scroll" }}>
                      <thead>
                        <tr>
                          <th className="text-center">Name</th>
                          <th className="text-center">Icon</th>
                          <th className="text-center">Chapters</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((courseVal, index) => (
                          <tr key={index}>
                            <td className="text-center align-middle">
                              {courseVal.subjectName}
                            </td>
                            <td className="text-center align-middle">
                              <img
                                src={courseVal.icon}
                                alt=""
                                style={{ width: "60px" }}
                              />
                            </td>
                            <td className="text-center align-middle">
                              <Link
                                to={`/fundamentals/chapters/${
                                  courseVal._id ? courseVal._id : null
                                }`}
                              >
                                <button className="btn btn-outline-primary btn-sm">
                                  View Chapters
                                </button>
                              </Link>
                            </td>
                            <td className="text-center align-middle">
                              <Link
                                to={`/fundamentals/subjects/edit/${
                                  courseVal._id ? courseVal._id : null
                                }`}
                              >
                                <button className="btn btn-outline-info btn-sm">
                                  <MdEdit className="viewbtn" />
                                </button>
                              </Link>

                              <button
                                className="btn btn-outline-danger btn-sm ms-2"
                                onClick={() => handleDeleteClick(courseVal._id)}
                              >
                                <MdDelete className="viewbtn" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
}

export default FundamentalSubjects;
